import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, PlusCircle, Building2, Briefcase, Calendar, ChevronDown, ChevronUp, FileText, CheckCircle2, Trash2, AlertTriangle } from 'lucide-react';
import { api, paymentApi } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

interface Interview {
  id: string;
  company: string;
  position: string;
  description: string;
  resume_url: string | null;
  match_score: number | null;
  created_at: string;
  interview_suggestions: Array<{ suggestion: string }>;
  interview_questions: Array<{ question: string; answer: string }>;
}

function History() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [interviews, setInterviews] = useState<Interview[]>([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  useEffect(() => {
    loadInterviews();
  }, []);

  const loadInterviews = async () => {
    try {
      setLoading(true);
      const data = await api.getInterviews();
      setInterviews(data);
    } catch (error) {
      console.error('加载历史记录失败:', error);
      alert('加载历史记录失败');
    } finally {
      setLoading(false);
    }
  };

  const handleStartInterview = async () => {
    if (!user) {
      navigate('/auth');
      return;
    }

    try {
      // 检查剩余次数
      const credits = await paymentApi.getUserCredits(user.id);
      if (credits.remaining_count < 1) {
        // 如果没有剩余次数，跳转到购买页面
        navigate('/pricing');
        return;
      }

      // 有剩余次数，跳转到面试流程
      navigate('/interview/job');
    } catch (error) {
      console.error('检查面试次数失败:', error);
      // 出错时依然允许创建面试，避免影响用户体验
      navigate('/interview/job');
    }
  };

  const toggleExpand = (id: string) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleDelete = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation(); // 阻止冒泡，避免触发卡片点击
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!deleteId) return;

    // 1. 立即关闭弹窗
    setShowDeleteModal(false);
    
    // 2. 立即从前端列表中移除
    setInterviews(interviews.filter(i => i.id !== deleteId));
    
    // 3. 异步删除后端数据
    try {
      await api.deleteInterview(deleteId);
    } catch (error) {
      console.error('删除失败:', error);
      // 如果删除失败，可以通过 toast 提示用户，但不影响用户继续操作
      // 也可以选择恢复列表，但考虑到用户体验，建议只提示错误
    } finally {
      setDeleteId(null);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-600">加载中...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <header className="bg-white shadow-sm">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/')}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <ArrowLeft className="w-6 h-6" />
              </button>
              <h1 className="text-3xl font-bold text-gray-900">面试准备历史</h1>
            </div>
            <button 
              onClick={handleStartInterview}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              <PlusCircle className="w-5 h-5 mr-2" />
              新增面试陪跑
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="space-y-4">
          {interviews.length > 0 ? (
            interviews.map((record) => (
              <div 
                key={record.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                <div className="p-6">
                  <div className="flex justify-between items-start">
                    <div className="space-y-4">
                      <div className="flex items-center gap-2">
                        <Building2 className="w-5 h-5 text-gray-400" />
                        <span className="text-lg font-medium text-gray-900">
                          {record.company}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Briefcase className="w-5 h-5 text-gray-400" />
                        <span className="text-gray-600">
                          {record.position}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Calendar className="w-5 h-5 text-gray-400" />
                        <span className="text-sm text-gray-500">
                          {new Date(record.created_at).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={(e) => handleDelete(record.id, e)}
                        className="p-1 hover:bg-red-50 rounded-full transition-colors"
                      >
                        <Trash2 className="w-5 h-5 text-red-500 hover:text-red-600" />
                      </button>
                      <button
                        onClick={() => toggleExpand(record.id)}
                        className="inline-flex items-center px-4 py-2 text-blue-600 hover:text-blue-800 transition-colors"
                      >
                        {expandedId === record.id ? (
                          <>
                            收起详情
                            <ChevronUp className="w-5 h-5 ml-1" />
                          </>
                        ) : (
                          <>
                            查看详情
                            <ChevronDown className="w-5 h-5 ml-1" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                  {expandedId === record.id && (
                    <div className="mt-6 space-y-6 border-t pt-6">
                      {/* 岗位描述 */}
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-3">岗位描述</h3>
                        <div className="bg-gray-50 p-4 rounded-md whitespace-pre-line">
                          {record.description}
                        </div>
                      </div>

                      {/* 简历附件 */}
                      {record.resume_url && (
                        <div>
                          <h3 className="text-lg font-semibold text-gray-900 mb-3">简历附件</h3>
                          <div className="flex items-center gap-2 text-blue-600">
                            <FileText className="w-5 h-5" />
                            <a href={record.resume_url} target="_blank" rel="noopener noreferrer" className="hover:underline">
                              查看简历
                            </a>
                          </div>
                        </div>
                      )}

                      {/* 匹配度和建议 */}
                      {record.match_score !== null && Array.isArray(record.interview_suggestions) && record.interview_suggestions.length > 0 && (
                        <div>
                          <div className="flex items-center justify-between mb-4">
                            <h3 className="text-lg font-semibold text-gray-900">简历&岗位匹配度</h3>
                            <div className="text-3xl font-bold text-blue-600">{record.match_score}</div>
                          </div>
                          <div className="space-y-2">
                            {record.interview_suggestions.map((suggestion, index) => (
                              <div key={index} className="flex items-start">
                                <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                                <span className="text-gray-600">{suggestion.suggestion}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* 面试问题 */}
                      {Array.isArray(record.interview_questions) && record.interview_questions.length > 0 && (
                        <div>
                          <h3 className="text-lg font-semibold text-gray-900 mb-4">模拟面试问题</h3>
                          <div className="space-y-4">
                            {record.interview_questions.map((item, index) => (
                              <div key={index} className="space-y-2">
                                <h4 className="font-medium text-gray-900">
                                  问题 {index + 1}：{item.question}
                                </h4>
                                <div className="bg-gray-50 p-4 rounded-md">
                                  <p className="text-gray-600">{item.answer}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-12 bg-white rounded-lg shadow-lg">
              <p className="text-gray-500">暂无历史记录</p>
            </div>
          )}
        </div>
      </main>

      {/* 删除确认弹窗 */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex items-center space-x-3 mb-4">
              <AlertTriangle className="w-6 h-6 text-red-500" />
              <p className="text-gray-900 font-medium">确认删除</p>
            </div>
            <p className="text-gray-600 mb-6">
              删除后将无法恢复，是否确认删除这条面试准备记录？
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md transition-colors"
              >
                取消
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                确认删除
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default History; 