import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Upload, X, AlertTriangle, FileText, Calendar, CheckCircle2, Trash2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { api } from '../services/api';

// 添加 HistoricalResume 接口定义
interface HistoricalResume {
  id: string;
  user_id: string;
  name: string;
  url: string;
  text: string;
  size: number;
  created_at: string;
}

function ResumeUpload() {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isParsing, setIsParsing] = useState(false);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [selectedHistoricalId, setSelectedHistoricalId] = useState<string | null>(null);
  const [historicalResumes, setHistoricalResumes] = useState<HistoricalResume[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  // 文件大小格式化函数
  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${(bytes / Math.pow(k, i)).toFixed(1)} ${sizes[i]}`;
  };

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && (droppedFile.type === 'application/pdf' || 
        droppedFile.type === 'application/msword' || 
        droppedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      handleFileUpload(droppedFile);
    }
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      handleFileUpload(selectedFile);
    }
  };

  const handleFileUpload = async (uploadFile: File) => {
    try {
      setIsUploading(true);
      setIsParsing(true); // 开始解析
      const interviewId = localStorage.getItem('currentInterviewId');
      if (!interviewId) {
        throw new Error('未找到面试记录ID');
      }

      // 上传文件
      const result = await api.uploadResume(interviewId, uploadFile);
      
      // 重新加载历史简历列表
      const resumes = await api.getHistoricalResumes();
      setHistoricalResumes(resumes);

      // 找到刚上传的简历并选中
      const justUploadedResume = resumes.find(r => r.url === result.resume_url);
      if (justUploadedResume) {
        setSelectedHistoricalId(justUploadedResume.id);
      }

      // 清除文件选择
      setFile(null);
    } catch (error) {
      console.error('上传失败:', error);
      alert('上传简历失败: ' + (error instanceof Error ? error.message : '未知错误'));
    } finally {
      setIsUploading(false);
      setIsParsing(false); // 结束解析
    }
  };

  const handleSubmit = async () => {
    if (!selectedHistoricalId) {
      navigate('/interview/analysis');
      return;
    }

    const interviewId = localStorage.getItem('currentInterviewId');
    if (!interviewId) {
      alert('未找到面试记录ID');
      return;
    }

    // 1. 立即跳转
    navigate('/interview/analysis');

    // 2. 后台异步处理
    try {
      await api.uploadResume(interviewId, selectedHistoricalId);
    } catch (error) {
      console.error('使用历史简历失败:', error);
      // 错误处理可以通过全局消息提示或其他方式展示
    }
  };

  const handleSkipClick = () => {
    setShowSkipModal(true);
  };

  const handleSkipConfirm = () => {
    setShowSkipModal(false);
    navigate('/interview/analysis');
  };

  const handlePrevious = () => {
    navigate('/interview/job');
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleSelectHistorical = (id: string) => {
    setSelectedHistoricalId(id);
    setFile(null); // 清除新上传的文件
  };

  const handleDeleteHistorical = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation(); // 阻止冒泡，避免触发选择事件
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!deleteId) return;

    // 1. 立即关闭弹窗
    setShowDeleteModal(false);

    // 2. 立即从前端列表中移除
    setHistoricalResumes(historicalResumes.filter(r => r.id !== deleteId));
    
    // 如果删除的是当前选中的简历，清除选中状态
    if (deleteId === selectedHistoricalId) {
      setSelectedHistoricalId(null);
    }

    // 3. 异步删除后端数据
    try {
      await api.deleteHistoricalResume(deleteId);
    } catch (error) {
      console.error('删除失败:', error);
      // 如果删除失败，只记录日志，不影响用户体验
    } finally {
      setDeleteId(null);
    }
  };

  const renderUploadStatus = () => {
    if (isUploading) {
      return (
        <div className="flex items-center space-x-2 text-blue-600">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
          <p>上传中...</p>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const loadHistoricalResumes = async () => {
      try {
        setIsLoading(true);
        setLoadError(null);
        const resumes = await api.getHistoricalResumes();
        console.log('加载到的历史简历:', resumes);
        setHistoricalResumes(resumes);
      } catch (error) {
        console.error('加载历史简历失败:', error);
        setLoadError(error instanceof Error ? error.message : '加载失败');
      } finally {
        setIsLoading(false);
      }
    };

    loadHistoricalResumes();
  }, []);

  // 修改拖放区域样式
  const isProcessing = isUploading || isParsing;

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="flex items-center justify-center space-x-2">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
          <span className="text-gray-600">加载历史简历...</span>
        </div>
      </div>
    );
  }

  if (loadError) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="text-red-500 text-center">
          <AlertTriangle className="w-6 h-6 mx-auto mb-2" />
          <p>{loadError}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">上传简历</h2>
        
        {/* 历史简历选择 - 添加条件渲染和加载状态 */}
        {historicalResumes.length > 0 && (
          <div className="mb-8">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">已上传的简历</h3>
            <div className="space-y-4">
              {historicalResumes.map((resume) => (
                <div
                  key={resume.id}
                  onClick={() => handleSelectHistorical(resume.id)}
                  className={`flex items-center p-4 border rounded-lg cursor-pointer transition-colors ${
                    selectedHistoricalId === resume.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'hover:border-gray-300'
                  }`}
                >
                  <div className="p-2 bg-gray-100 rounded">
                    <FileText className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="ml-4 flex-1">
                    <div className="font-medium text-gray-900">{resume.name}</div>
                    <div className="flex items-center text-sm text-gray-500 mt-1">
                      <Calendar className="w-4 h-4 mr-1" />
                      <span>上传于 {new Date(resume.created_at).toLocaleDateString()}</span>
                      <span className="mx-2">·</span>
                      <span>{formatFileSize(resume.size)}</span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {selectedHistoricalId === resume.id && (
                      <CheckCircle2 className="w-5 h-5 text-blue-500" />
                    )}
                    <button
                      onClick={(e) => handleDeleteHistorical(resume.id, e)}
                      className="p-1 hover:bg-red-50 rounded-full transition-colors"
                    >
                      <Trash2 className="w-5 h-5 text-red-500 hover:text-red-600" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!file ? (
          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`border-2 border-dashed rounded-lg p-8 text-center ${
              isProcessing 
                ? 'bg-gray-100 cursor-not-allowed opacity-75'
                : isDragging 
                  ? 'border-blue-500 bg-blue-50' 
                  : 'border-gray-300'
            }`}
            style={{ pointerEvents: isProcessing ? 'none' : 'auto' }}
          >
            <Upload className={`w-12 h-12 mx-auto mb-4 ${isProcessing ? 'text-gray-400' : 'text-gray-400'}`} />
            <p className={`text-gray-600 mb-2 ${isProcessing ? 'opacity-75' : ''}`}>
              {isProcessing ? '正在处理文件...' : '拖拽文件到这里，或者'}
              {!isProcessing && (
                <label className="text-blue-600 hover:text-blue-700 cursor-pointer mx-1">
                  点击上传
                  <input
                    type="file"
                    className="hidden"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    disabled={isProcessing}
                  />
                </label>
              )}
            </p>
            {!isProcessing && (
              <p className="text-sm text-gray-500">支持 PDF、Word 格式</p>
            )}
          </div>
        ) : (
          <div className="space-y-4">
            <div className="bg-gray-50 rounded-lg p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-blue-100 rounded">
                    <Upload className="w-6 h-6 text-blue-600" />
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">{file.name}</p>
                    <p className="text-sm text-gray-500">
                      {(file.size / (1024 * 1024)).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <button
                  onClick={handleRemoveFile}
                  className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                >
                  <X className="w-5 h-5 text-gray-500" />
                </button>
              </div>
            </div>
            
            {renderUploadStatus()}
          </div>
        )}

        <div className="mt-8 space-y-4">
          <div className="flex justify-between items-center">
            <button
              onClick={() => navigate('/interview/job')}
              className="px-6 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              disabled={isUploading || isParsing}
            >
              上一步
            </button>
            <button
              onClick={handleSubmit}
              disabled={isUploading || isParsing}
              className={`px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors ${
                (isUploading || isParsing) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isUploading || isParsing ? '处理中...' : '下一步'}
            </button>
          </div>
          {!file && (
            <div className="flex justify-end">
              <button
                onClick={() => setShowSkipModal(true)}
                className="text-gray-400 hover:text-gray-600 text-sm transition-colors"
              >
                跳过
              </button>
            </div>
          )}
        </div>
      </div>

      {showSkipModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex items-center space-x-3 mb-4">
              <AlertTriangle className="w-6 h-6 text-yellow-500" />
              <p className="text-gray-900 font-medium">提示</p>
            </div>
            <p className="text-gray-600 mb-6">
              未上传简历进行分析，AI只能给出通用的简历优化建议和面试问题，效果可能会大打折扣。
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowSkipModal(false)}
                className="px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-md transition-colors"
              >
                去上传简历
              </button>
              <button
                onClick={handleSkipConfirm}
                className="px-4 py-2 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition-colors"
              >
                不上传，直接分析
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 删除确认弹窗 */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex items-center space-x-3 mb-4">
              <AlertTriangle className="w-6 h-6 text-red-500" />
              <p className="text-gray-900 font-medium">确认删除</p>
            </div>
            <p className="text-gray-600 mb-6">
              删除后将无法恢复，是否确认删除这份简历？
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md transition-colors"
              >
                取消
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                确认删除
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ResumeUpload;