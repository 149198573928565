import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle, XCircle, Loader2 } from 'lucide-react';
import { paymentApi } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

function OrderResult() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const [status, setStatus] = useState<'success' | 'error' | 'loading'>('loading');
  const [message, setMessage] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 30; // 最多重试30次，即60秒

  const checkOrderStatus = async (orderNo: string) => {
    try {
      console.log(`第 ${retryCount + 1} 次查询订单状态...`);
      const order = await paymentApi.queryOrder(orderNo);
      console.log('订单状态:', order);
      
      if (order.status === 'paid') {
        console.log('订单支付成功');
        setStatus('success');
        setMessage('支付成功！');
      } else if (order.status === 'pending') {
        if (retryCount >= MAX_RETRIES) {
          console.log('超过最大重试次数');
          setStatus('error');
          setMessage('支付状态确认超时，请刷新页面重试');
          return;
        }
        
        console.log('订单待支付，继续轮询');
        setRetryCount(count => count + 1);
        setTimeout(() => checkOrderStatus(orderNo), 2000);
      } else {
        console.log('订单状态异常:', order.status);
        setStatus('error');
        setMessage(`支付失败: ${order.status}`);
      }
    } catch (err) {
      console.error('查询订单状态失败:', err);
      if (retryCount < MAX_RETRIES) {
        console.log('准备重试...');
        setRetryCount(count => count + 1);
        setTimeout(() => checkOrderStatus(orderNo), 2000);
      } else {
        setStatus('error');
        setMessage('查询订单状态失败，请刷新页面重试');
      }
    }
  };

  useEffect(() => {
    const orderNo = searchParams.get('out_trade_no');
    if (!orderNo) {
      setStatus('error');
      setMessage('订单号不存在');
      return;
    }

    console.log('开始检查订单状态:', orderNo);
    checkOrderStatus(orderNo);
  }, [searchParams]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-2xl shadow-lg p-8">
        {status === 'loading' && (
          <div className="text-center">
            <Loader2 className="h-12 w-12 text-blue-600 animate-spin mx-auto" />
            <h2 className="mt-4 text-xl font-semibold text-gray-900">
              正在确认支付结果...
            </h2>
            <p className="mt-2 text-sm text-gray-500">
              第 {retryCount + 1} 次查询中，请稍候...
            </p>
          </div>
        )}

        {status === 'success' && (
          <div className="text-center">
            <CheckCircle className="h-16 w-16 text-green-500 mx-auto" />
            <h2 className="mt-4 text-2xl font-bold text-gray-900">
              {message}
            </h2>
            <div className="mt-8 space-y-4">
              <button
                onClick={() => navigate('/interview/job')}
                className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                开始面试
              </button>
              <button
                onClick={() => navigate('/user')}
                className="w-full py-3 px-6 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                查看我的账户
              </button>
            </div>
          </div>
        )}

        {status === 'error' && (
          <div className="text-center">
            <XCircle className="h-16 w-16 text-red-500 mx-auto" />
            <h2 className="mt-4 text-2xl font-bold text-gray-900">
              {message}
            </h2>
            <p className="mt-2 text-gray-600">
              如果您已完成支付，请稍后刷新页面查看
            </p>
            <div className="mt-8 space-y-4">
              <button
                onClick={() => {
                  setStatus('loading');
                  setRetryCount(0);
                  const orderNo = searchParams.get('out_trade_no');
                  if (orderNo) {
                    checkOrderStatus(orderNo);
                  }
                }}
                className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                重新查询
              </button>
              <button
                onClick={() => navigate('/pricing')}
                className="w-full py-3 px-6 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                返回购买页面
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderResult; 