import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  User, Settings, LogOut, 
  Bell, Key, ChevronRight, History,
  Loader2 
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { paymentApi } from '../services/api';

interface UserProfile {
  email: string;
  membershipStatus: 'free' | 'premium';
  notificationsEnabled: boolean;
  membershipExpiry?: string;
  remainingInterviews: number;
  totalPurchased: number;
}

function UserCenter() {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState<UserProfile>({
    email: user?.email || '',
    membershipStatus: 'free',
    notificationsEnabled: true,
    remainingInterviews: 0,
    totalPurchased: 0
  });

  useEffect(() => {
    loadUserProfile();
  }, []);

  const loadUserProfile = async () => {
    setIsLoading(true);
    try {
      // 加载用户基本信息
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user?.id)
        .single();

      if (error) throw error;

      // 加载用户面试次数
      const credits = await paymentApi.getUserCredits(user?.id || '');

      if (data) {
        setProfile({
          email: user?.email || '',
          membershipStatus: data.membership_status || 'free',
          notificationsEnabled: data.notifications_enabled || true,
          membershipExpiry: data.membership_expiry,
          remainingInterviews: credits.remaining_count,
          totalPurchased: credits.total_purchased
        });
      }
    } catch (error) {
      console.error('加载用户资料失败:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/auth');
    } catch (error) {
      console.error('退出登录失败:', error);
    }
  };

  const menuItems = [
    {
      icon: History,
      title: '面试历史',
      description: '查看您的面试准备记录',
      onClick: () => navigate('/history')
    },
    {
      icon: Bell,
      title: '消息通知',
      description: '管理系统通知和提醒',
      badge: profile.notificationsEnabled ? '已开启' : '已关闭',
      onClick: () => navigate('/settings/notifications')
    },
    {
      icon: Key,
      title: '账户设置',
      description: '修改昵称、账号和密码',
      onClick: () => navigate('/settings/password')
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* 顶部导航栏 */}
      <header className="bg-white shadow-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          <Link to="/" className="text-2xl font-bold text-gray-900 hover:text-blue-600 transition-colors">
            面试陪跑助手
          </Link>
          <button 
            onClick={handleLogout}
            className="text-gray-600 hover:text-gray-800 flex items-center px-4 py-2 rounded-lg hover:bg-gray-100 transition-all"
          >
            <LogOut className="w-4 h-4 mr-2" />
            退出登录
          </button>
        </div>
      </header>

      <main className="max-w-3xl mx-auto px-4 py-8">
        {/* 用户信息卡片 */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          <div className="p-8">
            <div className="flex items-start justify-between">
              <div className="flex items-center">
                <div className="w-20 h-20 bg-gradient-to-br from-blue-100 to-blue-50 rounded-full flex items-center justify-center shadow-inner">
                  <User className="w-10 h-10 text-blue-600" />
                </div>
                <div className="ml-6">
                  <h2 className="text-xl font-bold text-gray-900">{profile.email}</h2>
                  {isLoading ? (
                    <div className="flex items-center space-x-2 mt-2 text-gray-500">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>加载中...</span>
                    </div>
                  ) : (
                    <div className="mt-2 flex items-center">
                      <div className="px-3 py-1 bg-blue-50 rounded-full">
                        <p className="text-blue-700 font-medium">
                          剩余面试陪跑额度：{profile.remainingInterviews} 次
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button 
                  onClick={() => navigate('/pricing')}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors shadow-sm"
                >
                  充值
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* 功能菜单 */}
        <div className="mt-8 bg-white rounded-2xl shadow-lg divide-y">
          {menuItems.map((item, index) => (
            <div
              key={index}
              onClick={item.onClick}
              className="flex items-center justify-between p-6 hover:bg-gray-50 cursor-pointer transition-colors"
            >
              <div className="flex items-center">
                <div className="w-10 h-10 bg-blue-50 rounded-lg flex items-center justify-center">
                  <item.icon className="w-5 h-5 text-blue-600" />
                </div>
                <div className="ml-4">
                  <h3 className="font-medium text-gray-900">{item.title}</h3>
                  <p className="text-sm text-gray-500 mt-0.5">{item.description}</p>
                </div>
              </div>
              <div className="flex items-center">
                {item.badge && (
                  <span className="mr-3 text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded">{item.badge}</span>
                )}
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}

export default UserCenter; 