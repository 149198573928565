import React from 'react';
import { PlusCircle, LogIn, UserCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { paymentApi } from '../services/api';

function Home() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStartInterview = async () => {
    if (!user) {
      // 未登录时跳转到登录页面
      navigate('/auth');
      return;
    }

    try {
      // 检查剩余次数
      const credits = await paymentApi.getUserCredits(user.id);
      if (credits.remaining_count < 1) {
        // 如果没有剩余次数，跳转到购买页面
        navigate('/pricing');
        return;
      }

      // 有剩余次数，跳转到面试流程
      navigate('/interview/job');
    } catch (error) {
      console.error('检查面试次数失败:', error);
      // 出错时依然允许创建面试，避免影响用户体验
      navigate('/interview/job');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex items-center justify-between">
            <h1 className="text-3xl font-bold text-gray-900">
              面试陪跑助手
            </h1>
            {user ? (
              <button
                onClick={() => navigate('/user')}
                className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-full transition-colors flex items-center gap-2"
              >
                <UserCircle className="w-6 h-6" />
                <span className="text-sm">用户中心</span>
              </button>
            ) : (
              <button
                onClick={() => navigate('/auth')}
                className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-full transition-colors flex items-center gap-2"
              >
                <LogIn className="w-6 h-6" />
                <span className="text-sm">登录/注册</span>
              </button>
            )}
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            AI驱动，3步完成面试全流程准备
          </h2>
          <p className="text-xl text-gray-600 mb-12">
            智能简历优化 · 面试问题预演 · 专业建议分析
          </p>
          
          <button 
            onClick={handleStartInterview}
            className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
              transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-xl
              animate-bounce-gentle"
          >
            <PlusCircle className="w-6 h-6 mr-2" />
            <span className="text-lg font-medium">新增面试陪跑</span>
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white rounded-lg shadow p-6">
            <div className="text-blue-600 text-lg font-semibold mb-3">第一步</div>
            <h3 className="text-xl font-bold mb-2">意向岗位</h3>
            <p className="text-gray-600">填写目标公司和岗位信息，开启你的求职之旅</p>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="text-blue-600 text-lg font-semibold mb-3">第二步</div>
            <h3 className="text-xl font-bold mb-2">简历准备</h3>
            <p className="text-gray-600">上传你的简历，或使用我们的智能模板生成器</p>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="text-blue-600 text-lg font-semibold mb-3">第三步</div>
            <h3 className="text-xl font-bold mb-2">深度分析</h3>
            <p className="text-gray-600">获取AI驱动的简历优化建议和面试问题预演</p>
          </div>
        </div>
      </main>

      <footer className="bg-gray-50 fixed bottom-0 w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <p className="text-center text-gray-500">
            面试陪跑助手 - AI驱动的面试准备平台     V1.0
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Home;