import React, { useState, useEffect, useRef } from 'react';
import { CheckCircle2, AlertCircle, ChevronRight, ThumbsUp } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import AnalysisLoading from './AnalysisLoading';

function Analysis() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [analysis, setAnalysis] = useState<{
    matchScore: number;
    suggestions: string[];
    questions: Array<{ question: string; answer: string }>;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  
  const analysisStarted = useRef(false);

  useEffect(() => {
    const startAnalysis = async () => {
      if (analysisStarted.current) return;
      analysisStarted.current = true;
      
      try {
        setLoading(true);
        const interviewId = localStorage.getItem('currentInterviewId');
        if (!interviewId) {
          throw new Error('未找到面试记录ID');
        }

        // 先获取分析结果
        const result = await api.startAnalysis(interviewId);
        console.log('Edge Function 返回结果:', result);

        // 然后获取完整的面试记录
        const interview = await api.getInterview(interviewId);
        console.log('数据库记录:', interview);

        // 格式化数据
        setAnalysis({
          matchScore: interview.match_score,
          suggestions: interview.interview_suggestions.map(s => s.suggestion),
          questions: interview.interview_questions.map(q => ({
            question: q.question,
            answer: q.answer
          }))
        });

      } catch (error) {
        console.error('分析过程出错:', error);
        setError(error instanceof Error ? error.message : '分析失败');
      } finally {
        setLoading(false);
      }
    };

    startAnalysis();
  }, []);

  const handleComplete = () => {
    localStorage.removeItem('currentInterviewId'); // 清理本地存储
    navigate('/history');
  };

  if (loading) {
    return <AnalysisLoading />;
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          {error}
        </div>
      </div>
    );
  }

  if (!analysis) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="text-center">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <p className="text-gray-600">分析过程出现错误，请重试</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-900">简历&岗位匹配度</h2>
          <div className="text-4xl font-bold text-blue-600">{analysis.matchScore}</div>
        </div>
        
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-gray-900">优化建议</h3>
          <ul className="space-y-2">
            {analysis.suggestions.map((suggestion, index) => (
              <li key={index} className="flex items-start">
                <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-gray-600">{suggestion}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">模拟面试问题</h2>
        <div className="space-y-6">
          {analysis.questions.map((item, index) => (
            <div key={index} className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-medium">
                  {index + 1}
                </div>
                <div className="flex-1">
                  <p className="font-medium text-gray-900 mb-3">{item.question}</p>
                  <p className="text-gray-600 leading-relaxed indent-8 whitespace-pre-line">
                    {item.answer}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center pt-4">
        <button
          onClick={handleComplete}
          className="px-8 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 
            transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-xl"
        >
          完成
        </button>
      </div>
    </div>
  );
}

export default Analysis;