import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { paymentApi } from '../services/api';
import { Check, X } from 'lucide-react';

interface Package {
  id: string;
  name: string;
  price: number;
  interview_count: number;
  description: string;
}

function Pricing() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [packages, setPackages] = useState<Package[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
  const [currentOrderNo, setCurrentOrderNo] = useState<string | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = async () => {
    try {
      const data = await paymentApi.getPackages();
      setPackages(data);
    } catch (err) {
      setError('加载套餐信息失败');
      console.error('Failed to load packages:', err);
    }
  };

  const handlePurchase = async (packageId: string) => {
    if (!user) {
      navigate('/auth');
      return;
    }

    setLoading(true);
    setError(null);

    // 设置当前选择的套餐
    const packageToSelect = packages.find(p => p.id === packageId);
    setSelectedPackage(packageToSelect || null);

    try {
      const { payUrl, orderNo } = await paymentApi.createOrder(packageId);
      setPaymentUrl(payUrl);
      setCurrentOrderNo(orderNo);
    } catch (err) {
      setError('创建订单失败，请稍后重试');
      console.error('Failed to create order:', err);
    } finally {
      setLoading(false);
    }
  };

  // 检查订单状态
  useEffect(() => {
    if (!currentOrderNo) return;

    const checkOrderStatus = async () => {
      try {
        const order = await paymentApi.queryOrder(currentOrderNo);
        
        if (order.status === 'paid') {
          // 更新用户面试次数
          if (user?.id) {
            await paymentApi.refreshUserCredits(user.id);
          }
          
          // 清除支付窗口状态
          setPaymentUrl(null);
          setCurrentOrderNo(null);
          
          // 跳转到结果页面
          navigate('/order/result?out_trade_no=' + currentOrderNo);
        } else if (order.status === 'failed') {
          setError('支付失败，请重试');
          setPaymentUrl(null);
          setCurrentOrderNo(null);
        }
        // pending 状态继续轮询
      } catch (err) {
        console.error('Failed to check order status:', err);
        setError('查询支付状态失败，请刷新页面重试');
      }
    };

    const interval = setInterval(checkOrderStatus, 2000);
    return () => clearInterval(interval);
  }, [currentOrderNo, navigate, user?.id]);

  const features = [
    '智能简历分析',
    '面试问题生成',
    '建议和反馈',
    '历史记录保存'
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* 标题部分 */}
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            选择适合您的套餐
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            我们提供多种套餐选择，助您在面试中脱颖而出
          </p>
        </div>

        {/* 错误提示 */}
        {error && (
          <div className="mt-8 text-center text-red-600 bg-red-50 py-3 px-4 rounded-lg">
            {error}
          </div>
        )}

        {/* 套餐卡片 */}
        <div className="mt-16 grid gap-8 lg:grid-cols-3 lg:gap-x-8">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className="relative bg-white rounded-2xl shadow-lg overflow-hidden"
            >
              <div className="p-8">
                <h3 className="text-2xl font-semibold text-gray-900">
                  {pkg.name}
                </h3>
                <p className="mt-4 text-gray-500">{pkg.description}</p>
                <div className="mt-6">
                  <p className="text-5xl font-bold text-gray-900">
                    ¥{pkg.price}
                  </p>
                  <p className="mt-2 text-gray-500">
                    包含 {pkg.interview_count} 次面试机会
                  </p>
                </div>

                {/* 功能列表 */}
                <ul className="mt-8 space-y-4">
                  {features.map((feature) => (
                    <li key={feature} className="flex items-center">
                      <Check className="h-5 w-5 text-green-500" />
                      <span className="ml-3 text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="px-8 pb-8">
                <button
                  onClick={() => handlePurchase(pkg.id)}
                  disabled={loading}
                  className={`w-full py-3 px-6 rounded-lg text-white text-lg font-semibold
                    ${loading 
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-blue-600 hover:bg-blue-700'
                    } transition-colors`}
                >
                  {loading ? '处理中...' : '立即购买'}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* 说明文字 */}
        <div className="mt-12 text-center text-gray-600">
          <p>支付宝付款 · 安全支付保障 · 7×24小时客服支持</p>
        </div>

        {/* 支付弹窗 */}
        {paymentUrl && (
          <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-4 animate-in fade-in duration-300">
            <div className="bg-white rounded-2xl shadow-2xl w-full max-w-3xl overflow-hidden">
              {/* 顶部标题栏 */}
              <div className="flex justify-between items-center px-6 py-5 border-b border-gray-100">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900">完成支付</h3>
                  <p className="text-sm text-gray-500 mt-1">请使用支付宝完成付款</p>
                </div>
                <button
                  onClick={() => {
                    setPaymentUrl(null);
                    setCurrentOrderNo(null);
                  }}
                  className="text-gray-400 hover:text-gray-500 transition-colors rounded-full p-1 hover:bg-gray-100"
                  aria-label="关闭"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              {/* 主要内容区域 */}
              <div className="flex flex-col md:flex-row">
                {/* 左侧：订单信息 */}
                <div className="p-6 md:w-2/5 space-y-5 border-b md:border-b-0 md:border-r border-gray-100">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">订单信息</h4>
                    <div className="space-y-3">
                      <div>
                        <p className="text-xs text-gray-500">商品名称</p>
                        <p className="text-base font-medium text-gray-900">
                          面试陪跑服务 - {selectedPackage?.name || '标准套餐'}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs text-gray-500">订单编号</p>
                        <p className="text-xs font-mono text-gray-600">{currentOrderNo}</p>
                      </div>
                      <div>
                        <p className="text-xs text-gray-500">支付金额</p>
                        <p className="text-xl font-semibold text-blue-600">
                          ¥{selectedPackage?.price || '0'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="pt-4 border-t border-gray-100">
                    <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">支付说明</h4>
                    <ul className="space-y-2.5">
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-50 text-blue-600 flex items-center justify-center mr-2.5 mt-0.5 text-xs">1</span>
                        打开支付宝 App
                      </li>
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-50 text-blue-600 flex items-center justify-center mr-2.5 mt-0.5 text-xs">2</span>
                        扫描右侧二维码
                      </li>
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-50 text-blue-600 flex items-center justify-center mr-2.5 mt-0.5 text-xs">3</span>
                        确认付款金额并支付
                      </li>
                    </ul>
                  </div>
                </div>

                {/* 右侧：二维码区域 */}
                <div className="p-6 md:w-3/5 flex flex-col items-center justify-center bg-gradient-to-br from-blue-50 to-white">
                  <div className="relative w-full max-w-[280px] mx-auto">
                    <div className="absolute -inset-3 bg-gradient-to-r from-blue-100 to-blue-50 rounded-2xl blur-sm opacity-75"></div>
                    <div className="relative bg-white p-4 rounded-xl shadow-sm border border-blue-100 flex items-center justify-center">
                      <iframe
                        src={paymentUrl}
                        className="w-[203px] h-[203px] border-0 overflow-hidden"
                        title="支付宝支付"
                        scrolling="no"
                        style={{ display: 'block', margin: '0 auto' }}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-6">
                    <div className="flex items-center justify-center mb-2">
                      <span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></span>
                      <p className="text-sm font-medium text-gray-700">等待支付中...</p>
                    </div>
                    <p className="text-xs text-gray-500">支付完成后将自动跳转</p>
                  </div>
                </div>
              </div>

              {/* 底部信息 */}
              <div className="border-t border-gray-100 px-6 py-4 bg-gray-50">
                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center text-gray-500">
                    <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                    支付安全由支付宝保障
                  </div>
                  <div className="flex items-center space-x-3">
                    <span className="text-gray-500">遇到问题？</span>
                    <button className="text-blue-600 hover:text-blue-700 hover:underline">联系客服</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Pricing; 