import { supabase } from '../lib/supabase';

// 重试配置
const RETRY_COUNT = 3;
const RETRY_DELAY = 1000;

// 通用重试函数
const retryOperation = async <T>(
  operation: () => Promise<T>,
  retries = RETRY_COUNT,
  delay = RETRY_DELAY
): Promise<T> => {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0) {
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryOperation(operation, retries - 1, delay * 2);
    }
    throw error;
  }
};

// 添加 PDF.js 相关类型
interface PDFPageContent {
  items: Array<{ str: string }>;
}

interface PDFPage {
  getTextContent(): Promise<PDFPageContent>;
}

interface PDFDocument {
  numPages: number;
  getPage(pageNumber: number): Promise<PDFPage>;
}

// 将 readFileContent 移到对象外部
const readFileContent = async (file: File): Promise<string> => {
  try {
    if (file.type === 'application/pdf') {
      // 使用全局的 pdfjsLib
      const pdfjsLib = (window as any).pdfjsLib;
      
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({data: arrayBuffer}).promise as PDFDocument;
      
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const content = await page.getTextContent();
        const pageText = content.items.map(item => item.str).join(' ');
        text += pageText + '\n';
      }
      return text;

    } else if (
      file.type === 'application/msword' || 
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      try {
        const mammoth = await import('mammoth');
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.extractRawText({ arrayBuffer });
        return result.value;
      } catch (err) {
        const error = err as Error;
        throw new Error(`Word 处理失败: ${error.message}`);
      }
    }
    
    throw new Error(`不支持的文件格式: ${file.type}`);
  } catch (err) {
    const error = err as Error;
    throw new Error(`文件处理失败: ${error.message}`);
  }
};

// 支付相关错误类型
export class PaymentError extends Error {
  constructor(message: string, public code: string) {
    super(message);
    this.name = 'PaymentError';
  }
}

export const api = {
  // 创建面试记录
  createInterview: async (data: { 
    company: string; 
    position: string; 
    description: string; 
  }) => {
    try {
      // 获取当前用户
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('未登录');

      // 检查用户剩余次数
      const { data: credits, error: creditsError } = await supabase
        .from('user_interview_credits')
        .select('remaining_count')
        .eq('user_id', user.id)
        .single();

      if (creditsError && creditsError.code !== 'PGRST116') throw creditsError;
      
      const remainingCount = credits?.remaining_count || 0;
      if (remainingCount < 1) {
        throw new Error('面试次数不足，请先购买');
      }

      // 创建面试记录
      const { data: interview, error } = await supabase
        .from('interviews')
        .insert({
          ...data,
          user_id: user.id
        })
        .select()
        .single();
        
      if (error) throw error;

      // 扣除一次面试次数
      const { error: updateError } = await supabase
        .from('user_interview_credits')
        .upsert({
          user_id: user.id,
          remaining_count: remainingCount - 1,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id'
        });

      if (updateError) throw updateError;

      return interview;
    } catch (error) {
      console.error('创建面试记录失败:', error);
      throw error;
    }
  },

  // 获取历史简历
  getHistoricalResumes: async () => {
    try {
      console.log('开始获取历史简历');
      const { data: resumes, error } = await supabase
        .from('historical_resumes')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('获取历史简历错误:', error);
        throw error;
      }

      console.log('获取到的历史简历:', resumes);
      return resumes;
    } catch (error) {
      console.error('获取历史简历失败:', error);
      throw error;
    }
  },

  // 上传简历
  uploadResume: async (interviewId: string, file: File | string) => {
    try {
      // 如果 file 是字符串，说明是选择的历史简历 ID
      if (typeof file === 'string') {
        // 1. 获取历史简历记录
        const { data: historicalResume, error: fetchError } = await supabase
          .from('historical_resumes')
          .select('url, text')
          .eq('id', file)
          .single();

        if (fetchError) throw fetchError;

        // 2. 更新面试记录
        const { data: interview, error: updateError } = await supabase
          .from('interviews')
          .update({ 
            resume_url: historicalResume.url,
            resume_text: historicalResume.text
          })
          .eq('id', interviewId)
          .select()
          .single();

        if (updateError) throw updateError;
        return interview;
      }

      // 如果是新上传的文件
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('未登录');

      // 1. 读取文件内容
      let resumeText = await readFileContent(file);

      // 2. 上传文件到 storage
      const fileName = `${user.id}/${interviewId}/${Date.now()}.${file.name.split('.').pop()}`;
      const { data: uploadData, error: uploadError } = await supabase
        .storage
        .from('resumes')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // 3. 获取公开URL
      const { data: { publicUrl } } = supabase
        .storage
        .from('resumes')
        .getPublicUrl(fileName);

      // 4. 保存到历史简历
      const { data: historicalResume, error: saveError } = await supabase
        .from('historical_resumes')
        .insert({
          name: file.name,
          url: publicUrl,
          text: resumeText,
          size: file.size,
          user_id: user.id
        })
        .select()
        .single();

      if (saveError) throw saveError;

      // 5. 更新面试记录
      const { data: interview, error: updateError } = await supabase
        .from('interviews')
        .update({ 
          resume_url: publicUrl,
          resume_text: resumeText
        })
        .eq('id', interviewId)
        .select()
        .single();

      if (updateError) throw updateError;
      return interview;
    } catch (error) {
      console.error('上传过程出错:', error);
      throw error;
    }
  },

  // 开始分析
  startAnalysis: async (interviewId: string) => {
    try {
      const { data, error } = await supabase.functions.invoke('analyze-interview', {
        body: { interviewId }
      });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Analysis error:', error);
      throw error;
    }
  },

  // 获取历史记录
  getInterviews: async () => {
    const { data: interviews, error } = await supabase
      .from('interviews')
      .select(`
        id,
        company,
        position,
        description,
        resume_url,
        match_score,
        created_at,
        interview_suggestions (
          id,
          suggestion
        ),
        interview_questions (
          id,
          question,
          answer
        )
      `)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('获取历史记录失败:', error);
      throw error;
    }

    return interviews || [];
  },

  // 获取单个面试记录
  getInterview: async (id: string) => {
    const { data: interview, error } = await supabase
      .from('interviews')
      .select(`
        *,
        interview_suggestions (*),
        interview_questions (*)
      `)
      .eq('id', id)
      .single();

    if (error) throw error;
    return interview;
  },

  // 创建订单
  createOrder: async (planId: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new PaymentError('请先登录', 'AUTH_REQUIRED');

      return await retryOperation(async () => {
        const response = await fetch(`${import.meta.env.VITE_APP_URL}/api/create-order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({ 
            planId,
            returnUrl: import.meta.env.VITE_ALIPAY_RETURN_URL,
            notifyUrl: import.meta.env.VITE_ALIPAY_NOTIFY_URL
          })
        });

        if (!response.ok) {
          const error = await response.json();
          throw new PaymentError(error.error || '创建订单失败', 'CREATE_ORDER_FAILED');
        }

        return await response.json();
      });
    } catch (error) {
      if (error instanceof PaymentError) throw error;
      throw new PaymentError(
        error instanceof Error ? error.message : '创建订单失败',
        'UNKNOWN_ERROR'
      );
    }
  },

  // 查询订单状态
  getOrderStatus: async (orderId: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new PaymentError('请先登录', 'AUTH_REQUIRED');

      return await retryOperation(async () => {
        const { data: order, error } = await supabase
          .from('orders')
          .select('*')
          .eq('id', orderId)
          .single();

        if (error) throw new PaymentError('获取订单状态失败', 'GET_ORDER_FAILED');
        if (!order) throw new PaymentError('订单不存在', 'ORDER_NOT_FOUND');

        return {
          orderId: order.id,
          status: order.status,
          amount: order.amount,
          createdAt: new Date(order.created_at),
          paidAt: order.paid_at ? new Date(order.paid_at) : undefined
        };
      });
    } catch (error) {
      if (error instanceof PaymentError) throw error;
      throw new PaymentError(
        error instanceof Error ? error.message : '获取订单状态失败',
        'UNKNOWN_ERROR'
      );
    }
  },

  // 轮询订单状态
  pollOrderStatus: async (
    orderId: string, 
    onSuccess: (order: any) => void,
    onError: (error: Error) => void
  ) => {
    const maxAttempts = 60; // 最多轮询60次，即5分钟
    let attempts = 0;

    const poll = async () => {
      try {
        const order = await api.getOrderStatus(orderId);
        
        if (order.status === 'success') {
          onSuccess(order);
          return;
        }
        
        if (order.status === 'failed') {
          onError(new PaymentError('支付失败', 'PAYMENT_FAILED'));
          return;
        }

        if (attempts++ < maxAttempts) {
          setTimeout(poll, 5000); // 每5秒轮询一次
        } else {
          onError(new PaymentError('支付超时', 'PAYMENT_TIMEOUT'));
        }
      } catch (error) {
        onError(error instanceof Error ? error : new Error('未知错误'));
      }
    };

    poll();
  },

  // 删除历史简历
  deleteHistoricalResume: async (id: string) => {
    try {
      const { error } = await supabase
        .from('historical_resumes')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      console.error('删除历史简历失败:', error);
      throw error;
    }
  },

  // 删除面试记录
  deleteInterview: async (id: string) => {
    try {
      const { error } = await supabase
        .from('interviews')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      console.error('删除面试记录失败:', error);
      throw error;
    }
  }
};

// 支付相关接口
export const paymentApi = {
  // 创建支付宝订单
  createOrder: async (packageId: string) => {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('未登录');

      const { data, error } = await supabase.functions.invoke('create-alipay-order', {
        body: { packageId, userId: user.id }
      });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('创建订单失败:', error);
      throw error;
    }
  },

  // 查询订单状态
  queryOrder: async (orderNo: string) => {
    try {
      const { data, error } = await supabase
        .from('orders')
        .select('*')
        .eq('order_no', orderNo)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('查询订单失败:', error);
      throw error;
    }
  },

  // 获取用户面试次数
  getUserCredits: async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('user_interview_credits')
        .select('*')
        .eq('user_id', userId)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      return data || { remaining_count: 0, total_purchased: 0 };
    } catch (error) {
      console.error('获取用户面试次数失败:', error);
      throw error;
    }
  },

  // 获取所有套餐信息
  getPackages: async () => {
    const { data, error } = await supabase
      .from('packages')
      .select('*')
      .order('interview_count', { ascending: true })

    if (error) {
      throw error
    }

    return data
  },

  // 刷新用户面试次数
  refreshUserCredits: async (userId: string) => {
    try {
      if (!userId) throw new Error('未提供用户ID');

      // 使用 retryOperation 函数进行重试
      return await retryOperation(async () => {
        const { data, error } = await supabase
          .from('user_interview_credits')
          .select('*')
          .eq('user_id', userId)
          .maybeSingle();

        if (error) {
          console.error('获取面试次数失败:', error);
          throw error;
        }

        // 如果没有记录，返回默认值
        return data || { remaining_count: 0, total_purchased: 0 };
      }, 5, 1000); // 最多重试5次，每次间隔1秒

    } catch (error) {
      console.error('刷新用户面试次数失败:', error);
      // 确保即使发生错误也返回一个有效的对象
      return { remaining_count: 0, total_purchased: 0 };
    }
  }
} 