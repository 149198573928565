import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import JobForm from '../components/JobForm';
import ResumeUpload from '../components/ResumeUpload';
import Analysis from '../components/Analysis';

function InterviewProcess() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const steps = [
    { path: 'job', label: '意向岗位' },
    { path: 'resume', label: '简历准备' },
    { path: 'analysis', label: '深度分析' }
  ];

  const currentStepIndex = steps.findIndex(step => 
    location.pathname.includes(step.path)
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <header className="bg-white shadow-sm">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex items-center justify-between">
            <h1 
              onClick={() => navigate('/')} 
              className="text-2xl font-bold text-gray-900 cursor-pointer hover:text-blue-600"
            >
              面试陪跑助手
            </h1>
            <div className="flex items-center space-x-4">
              {steps.map((step, index) => (
                <div 
                  key={step.path}
                  className={`flex items-center ${index !== 0 ? 'ml-4' : ''}`}
                >
                  <div 
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      index < currentStepIndex 
                        ? 'bg-green-500 text-white' 
                        : index === currentStepIndex 
                        ? 'bg-blue-600 text-white' 
                        : 'bg-gray-200 text-gray-600'
                    }`}
                  >
                    {index + 1}
                  </div>
                  <span className={`ml-2 ${
                    index === currentStepIndex ? 'text-blue-600 font-medium' : 'text-gray-500'
                  }`}>
                    {step.label}
                  </span>
                  {index < steps.length - 1 && (
                    <div className="w-8 h-px bg-gray-300 mx-2" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Routes>
          <Route path="job" element={<JobForm />} />
          <Route path="resume" element={<ResumeUpload />} />
          <Route path="analysis" element={<Analysis />} />
        </Routes>
      </main>
    </div>
  );
}

export default InterviewProcess;