import React, { useState, useEffect } from 'react';
import { Brain, FileSearch, BarChart3, MessageSquare, Sparkles } from 'lucide-react';

interface AnalysisStep {
  icon: React.ElementType;
  title: string;
  description: string;
  duration: number; // 每个步骤的持续时间（毫秒）
}

const steps: AnalysisStep[] = [
  {
    icon: FileSearch,
    title: '简历内容解析',
    description: '正在使用自然语言处理技术解析简历内容...',
    duration: 5000  // 5秒
  },
  {
    icon: Brain,
    title: '人岗匹配分析',
    description: '正在使用大模型分析你与该职位的匹配度...',
    duration: 8000  // 8秒
  },
  {
    icon: BarChart3,
    title: '简历优化建议',
    description: '正在基于行业知识库提出针对性简历优化建议...',
    duration: 15000  // 15秒
  },
  {
    icon: MessageSquare,
    title: '面试问答生成',
    description: '正在生成【你会被问到的】面试问题和推荐回答...',
    duration: 15000  // 15秒
  }
];

function AnalysisLoading() {
  const [currentStep, setCurrentStep] = useState(0);
  const [dots, setDots] = useState('');
  const [progress, setProgress] = useState(0);

  // 动态省略号
  useEffect(() => {
    const timer = setInterval(() => {
      setDots(prev => prev.length >= 3 ? '' : prev + '.');
    }, 500);

    return () => clearInterval(timer);
  }, []);

  // 处理步骤进度
  useEffect(() => {
    let startTime = Date.now();
    
    // 计算总持续时间
    const totalDuration = steps.reduce((sum, step) => sum + step.duration, 0);
    
    // 计算当前步骤之前的所有持续时间
    const previousDuration = steps
      .slice(0, currentStep)
      .reduce((sum, step) => sum + step.duration, 0);

    const timer = setInterval(() => {
      const elapsed = Date.now() - startTime;
      
      // 计算当前步骤的进度
      if (elapsed >= steps[currentStep].duration) {
        // 当前步骤完成，进入下一步
        if (currentStep < steps.length - 1) {
          setCurrentStep(prev => prev + 1);
          startTime = Date.now();
        }
      }

      // 更新总体进度
      const currentProgress = Math.min(elapsed, steps[currentStep].duration);
      const totalProgress = ((previousDuration + currentProgress) / totalDuration) * 100;
      setProgress(Math.min(totalProgress, 100));

    }, 100);

    return () => clearInterval(timer);
  }, [currentStep]);

  return (
    <div className="bg-white rounded-lg shadow-lg p-8 max-w-2xl mx-auto">
      {/* 顶部标题 */}
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">AI 深度分析中</h2>
        <p className="text-gray-600">
          正在为您进行全方位分析{dots}
        </p>
        {/* 总进度条 */}
        <div className="mt-4 h-2 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="h-full bg-blue-600 rounded-full transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {/* 进度步骤 */}
      <div className="space-y-6">
        {steps.map((step, index) => {
          const isActive = index === currentStep;
          const isPast = index < currentStep;

          return (
            <div
              key={index}
              className={`flex items-center space-x-4 p-4 rounded-lg transition-all duration-300 ${
                isActive ? 'bg-blue-50' : ''
              }`}
            >
              <div
                className={`p-2 rounded-full ${
                  isActive
                    ? 'bg-blue-100 text-blue-600 animate-pulse'
                    : isPast
                    ? 'bg-green-100 text-green-600'
                    : 'bg-gray-100 text-gray-400'
                }`}
              >
                <step.icon className="w-6 h-6" />
              </div>
              <div className="flex-1">
                <h3 className={`font-medium ${
                  isActive ? 'text-blue-900' : isPast ? 'text-green-900' : 'text-gray-400'
                }`}>
                  {step.title}
                </h3>
                <p className={`text-sm ${
                  isActive ? 'text-blue-600' : isPast ? 'text-green-600' : 'text-gray-400'
                }`}>
                  {step.description}
                </p>
              </div>
              {isPast && (
                <Sparkles className="w-5 h-5 text-green-500" />
              )}
            </div>
          );
        })}
      </div>

      {/* AI 能力提示 */}
      <div className="mt-8 p-4 bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg">
        <div className="flex items-center text-sm text-gray-600">
          <Brain className="w-4 h-4 mr-2 text-blue-500" />
          <span>
            正在调用 AI 大模型分析能力，结合招聘行业专家经验，为您提供精准分析
          </span>
        </div>
      </div>
    </div>
  );
}

export default AnalysisLoading; 