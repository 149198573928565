import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { api } from '../services/api';

const jobSchema = z.object({
  company: z.string().min(1, '请输入公司名称'),
  position: z.string().min(1, '请输入职位名称'),
  description: z.string().min(10, '职位描述至少需要10个字符')
});

type JobFormData = z.infer<typeof jobSchema>;

function JobForm() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<JobFormData>({
    resolver: zodResolver(jobSchema)
  });

  const onSubmit = (data: JobFormData) => {
    // 1. 立即跳转到下一页
    navigate('/interview/resume');

    // 2. 异步创建记录
    api.createInterview(data)
      .then(interview => {
        // 保存面试ID到本地
        localStorage.setItem('currentInterviewId', interview.id);
        console.log('面试记录创建成功:', interview.id);
      })
      .catch(error => {
        console.error('创建面试记录失败:', error);
        // 如果创建失败，返回到表单页面
        navigate('/interview/job');
        alert('创建面试记录失败，请重试');
      });
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">填写意向岗位信息</h2>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
            公司名称
          </label>
          <input
            type="text"
            id="company"
            {...register('company')}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="例如：字节跳动"
          />
          {errors.company && (
            <p className="mt-1 text-sm text-red-600">{errors.company.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-1">
            职位名称
          </label>
          <input
            type="text"
            id="position"
            {...register('position')}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="例如：前端开发工程师"
          />
          {errors.position && (
            <p className="mt-1 text-sm text-red-600">{errors.position.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            职位描述
          </label>
          <textarea
            id="description"
            {...register('description')}
            rows={4}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="请粘贴完整的职位描述，包括职责要求、技能要求等"
          />
          {errors.description && (
            <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
          )}
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            下一步
          </button>
        </div>
      </form>
    </div>
  );
}

export default JobForm;